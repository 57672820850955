import { useState } from 'react';
import { DocumentViewerTopBar } from './DocumentViewerTopBar';
import TextViewer, { TestViewerPosition } from '../TextViewer/TextViewer';
import { Icons } from '@/assets';
import { DocumentProperties } from '@/services/api/actions/useDocumentProperties';
import { InDocumentSearchResult } from '@/services/api/actions/useInDocumentSearch';

const DocumentViewer = ({
  documentId,
  documentProperties,
  inDocumentSearchResults,
  pageScrollPosition,
  filePosition,
  focusLinksWith,
  onFilePositionChange,
  setFilePosition,
}: {
  documentId: string;
  query: string;
  documentProperties?: DocumentProperties;
  inDocumentSearchResults?: InDocumentSearchResult;
  pageScrollPosition: number;
  filePosition: TestViewerPosition;
  focusLinksWith: { remoteDocumentId: string; localPageNumber: number } | null;
  onFilePositionChange: (position: TestViewerPosition) => void;
  setFilePosition: (position: TestViewerPosition) => void;
}) => {
  const [scale, setScale] = useState(100);
  const [virtualizerLoaded, setVirtualizerLoaded] = useState(false);

  return (
    <>
      <div className="grid grid-cols-1 grid-rows-[auto_1fr] relative rounded-xl shadow-qura h-full w-full bg-white overflow-visible">
        <DocumentViewerTopBar
          scale={scale}
          setScale={setScale}
          documentProperties={documentProperties}
          documentId={documentId}
          documentUnitId={documentProperties?.documentUnitId}
          fileModifications={inDocumentSearchResults?.fileModifications}
          pageScrollPosition={pageScrollPosition}
          setFilePosition={setFilePosition}
        />
        {documentProperties && (
          <TextViewer
            documentId={documentId}
            documentUnitId={documentProperties?.documentUnitId}
            fileModifications={inDocumentSearchResults?.fileModifications}
            documentProperties={documentProperties}
            zoom={(scale / 100) * documentProperties.scaleFactor}
            filePosition={filePosition}
            onFilePositionChange={onFilePositionChange}
            onLoadSuccess={() => setVirtualizerLoaded(true)}
            focusLinksWith={focusLinksWith}
          />
        )}
        {(!virtualizerLoaded || !documentProperties) && (
          <div className="absolute bg-white top-[3.5rem] bottom-0 left-0 right-0 flex flex-col items-center justify-center">
            <Icons.LogoText className="h-8 mb-4 animate-pulse" />
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentViewer;
