import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { DocumentRelationsColumn } from './RelatedDocs';
import { ChunkPreviewColumn } from './ChunkPreview';
import Layout from '../../components/Layout/Layout';
import DocumentViewer from '../../components/DocumentViewer/DocumentViewer';
import { createNewSearch } from '../../state/actions/createNewSearch';
import { sendSearchParams } from '../../state/actions/sendSearchParams';
import { useSearchStore } from '../../state/searchStore';
import { loadOldSearch, setQuery, setSearchMode } from '../../state/actions/searchParams';
import { TestViewerPosition } from '../../components/TextViewer/TextViewer';
import { useDataRole } from '../../state/hooks/useDataRole';
import { useDocumentProperties } from '@/services/api/actions/useDocumentProperties';
import {
  InDocumentSearchResult,
  useInDocumentSearch,
} from '@/services/api/actions/useInDocumentSearch';
import { NotFound } from '@/features/Common/Error/404';
import { alertError } from '@/utils/alertError';
import { SearchResult } from '@/services/api/commonSchemas';
import { getOldSearch } from '@/services/firebase/functions/getOldSearch';

const DocumentPageGuard = () => {
  const navigate = useNavigate();
  const { documentId } = useParams();
  const { dataRoleUrlParam } = useDataRole();

  if (!documentId) {
    navigate(`/${dataRoleUrlParam}`);
    return <></>;
  }

  return <DocumentPage documentId={documentId} />;
};

const DocumentPage = ({ documentId }: { documentId: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { dataRole, dataRoleUrlParam } = useDataRole();
  const urlOffset = searchParams.get('offset');
  const urlPageNumber = searchParams.get('pageNumber');
  const urlSearchId = searchParams.get('searchId');
  const urlQuery = searchParams.get('query');
  const loadSearch = searchParams.get('loadSearch') === 'true';
  const searchMode = useSearchStore((state) => state.searchMode);

  // States
  const [searchIdBasedResults, setSearchIdBasedResults] = useState<InDocumentSearchResult | null>(
    null,
  );
  const [focusLinksWith, setFocusLinksWith] = useState<{
    remoteDocumentId: string;
    localPageNumber: number;
  } | null>(null);
  const [filePosition, setFilePosition] = useState<TestViewerPosition>({
    pageNumber: urlPageNumber ? parseInt(urlPageNumber) - 1 : 0,
    offset: urlOffset ? parseInt(urlOffset) : 0,
  });
  const [pageScrollPosition, setPageScrollPosition] = useState(filePosition.pageNumber);

  // Memos
  const mode = useMemo(() => {
    if (urlQuery) {
      return 'query';
    }
    if (urlSearchId && loadSearch) {
      return 'searchId';
    }
    return 'none';
  }, [urlQuery, urlSearchId, loadSearch]);
  const searchFromStore = useSearchStore((state) =>
    urlSearchId ? (state.crossDocumentSearches[urlSearchId] ?? null) : null,
  );
  const query = useMemo(
    () =>
      mode === 'query'
        ? urlQuery
        : mode === 'searchId'
          ? searchFromStore?.submittedParams.query
          : '',
    [mode, urlQuery, searchFromStore],
  );

  // Hooks
  const { data: documentProperties, isLoading: isDocumentPropertiesLoading } =
    useDocumentProperties(documentId);
  const { data: inQueryDocumentSearchResults, isLoading: isInQueryDocumentSearchResultsLoading } =
    useInDocumentSearch(
      documentId,
      urlQuery || '',
      documentProperties?.documentUnitId,
      mode === 'query',
    );

  // More Memos
  const inDocumentSearchResults = useMemo(
    () => (mode === 'query' ? inQueryDocumentSearchResults : searchIdBasedResults),
    [searchIdBasedResults, inQueryDocumentSearchResults, mode],
  );
  const isInDocumentSearchResultsLoading = useMemo(
    () => (mode === 'query' && isInQueryDocumentSearchResultsLoading) || false,
    [isInQueryDocumentSearchResultsLoading, mode],
  );
  const notFound = useMemo(
    () => !documentProperties && !isDocumentPropertiesLoading,
    [documentProperties, isDocumentPropertiesLoading],
  );

  // Functions
  const loadSearchIdBasedResults = useCallback(
    (search: SearchResult | null, query: string | null) => {
      if (!search || !query) return;

      const currentDocument = search.documents.find((d) => d.documentId === documentId);
      if (!currentDocument) return;

      setSearchIdBasedResults({
        documentId,
        query,
        fileModifications: currentDocument.fileProps,
        excerpts: currentDocument.outputChunks.map((c) => ({
          offset: c.heightOffset,
          pageNumber: c.pageNumber,
          fileModifications: c.excerpt,
          score: c.relevanceScore,
        })),
      });
    },
    [documentId],
  );

  // Effects
  useEffect(() => {
    if (!notFound) {
      setSearchMode('inDocumentSearch');
    } else {
      setSearchMode('crossDocumentSearch');
    }
  }, [notFound]);

  useEffect(() => {
    if (mode === 'searchId' && urlSearchId) {
      if (!searchFromStore) {
        getOldSearch(urlSearchId).then(({ result, submittedParams }) => {
          loadOldSearch(urlSearchId, result, submittedParams);
          setQuery(submittedParams.query);
        });
      } else {
        loadSearchIdBasedResults(searchFromStore.results, searchFromStore.submittedParams.query);
      }
    }
  }, [urlSearchId, searchFromStore, urlQuery, mode, loadSearchIdBasedResults]);

  // Update the page scroll position when the file position changes
  const handleFilePositionChange = useCallback((newPosition: TestViewerPosition) => {
    setPageScrollPosition(newPosition.pageNumber);
  }, []);

  const onSearch = (query: string) => {
    if (searchMode === 'inDocumentSearch') {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.delete('pageNumber');
        newParams.delete('offset');
        newParams.delete('loadSearch');
        newParams.set('query', query || '');
        return newParams;
      });
    } else {
      setQuery(query);
      createNewSearch()
        .then(({ searchId }) => {
          sendSearchParams(searchId, dataRole);
          navigate(`/${dataRoleUrlParam}/search/${searchId}`);
        })
        .catch((error) => {
          alertError(error, { function: 'onSearch', component: 'DocumentPage' }, { documentId });
          toast.error(t('errors.searchFailed'));
        });
    }
  };

  return (
    <Layout
      query={query || ''}
      onSearch={onSearch}
      showHelpButton={false}
      showBackButtonLink={urlSearchId ? `/${dataRoleUrlParam}/search/${urlSearchId}` : undefined}
      leftContent={() =>
        !notFound &&
        documentProperties && (
          <ChunkPreviewColumn
            documentId={documentId}
            documentUnitId={documentProperties?.documentUnitId}
            excerpts={inDocumentSearchResults?.excerpts || []}
            loading={isInDocumentSearchResultsLoading}
            notSearching={mode === 'none'}
            setFilePosition={setFilePosition}
            triggerDeepSearch={
              mode === 'searchId'
                ? () => {
                    onSearch(query || '');
                  }
                : undefined
            }
          />
        )
      }
      rightContent={() =>
        !notFound &&
        documentProperties && (
          <DocumentRelationsColumn
            documentId={documentId}
            documentUnitId={documentProperties.documentUnitId}
            dataRole={dataRole}
            query={query || ''}
            setFilePosition={setFilePosition}
            setFocusLinksWith={setFocusLinksWith}
          />
        )
      }
      contentClassName="w-[calc(100%-700px)] max-w-[850px] min-w-[75%] lg:min-w-[600px]">
      {!notFound && (
        <DocumentViewer
          documentId={documentId}
          query={query || ''}
          documentProperties={documentProperties}
          inDocumentSearchResults={inDocumentSearchResults || undefined}
          onFilePositionChange={handleFilePositionChange}
          setFilePosition={setFilePosition}
          pageScrollPosition={pageScrollPosition}
          filePosition={filePosition}
          focusLinksWith={focusLinksWith}
        />
      )}
      {notFound && (
        <NotFound
          title={t('errors.document404Title')}
          description={t('errors.document404Description')}
        />
      )}
    </Layout>
  );
};

export default DocumentPageGuard;
