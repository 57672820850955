import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PDFPreview } from '../../components/PDFPreview/PDFPreview';
import { MAX_CHUNKS_PER_DOCUMENT } from '../../constants';
import { TestViewerPosition } from '../../components/TextViewer/TextViewer';
import { FileModifications, PageModifications } from '@/services/api/commonSchemas';
import { Excerpt } from '@/services/api/actions/useInDocumentSearch';
import { Icons } from '@/assets';

const LoadingChunk = () => {
  return <div className="relative loading-horizontal border-none w-full h-36 rounded-md"></div>;
};

type ChunkPreviewProps = {
  documentId: string;
  documentUnitId: string;
  pageNumber: number;
  pageModifications: PageModifications;
  offset: number;
  score: number;
  setFilePosition: (position: TestViewerPosition) => void;
};
const ChunkPreview = ({
  documentId,
  documentUnitId,
  pageNumber,
  pageModifications,
  offset,
  setFilePosition,
}: ChunkPreviewProps) => {
  // const adjustedScore = adjustScore(score);
  const [hasLoaded, setHasLoaded] = useState(false);

  const fileModifications: FileModifications = useMemo(() => {
    return {
      pageOverrides: [pageModifications],
      pageSelection: [pageNumber],
    };
  }, [pageModifications, pageNumber]);

  return (
    <div
      className="flex flex-col items-center justify-between cursor-pointer flex-shrink-0 px-1 gap-2"
      onClick={() => setFilePosition({ pageNumber, offset })}>
      <PDFPreview
        noTextLayer
        showPageNumberTR
        hasLoaded={hasLoaded}
        onLoad={() => setHasLoaded(true)}
        documentId={documentId}
        documentUnitId={documentUnitId}
        fileModifications={fileModifications}
        className="rounded shadow-qura p-1"
        loadingLogoHeight={60}
        width={150}
        // adjustColorToScore={score}
      />
    </div>
  );
};

type ChunkPreviewColumnProps = {
  documentId: string;
  documentUnitId: string;
  excerpts: Excerpt[];
  loading: boolean;
  setFilePosition: (position: TestViewerPosition) => void;
  triggerDeepSearch?: () => void;
  notSearching: boolean;
};
export const ChunkPreviewColumn = ({
  documentId,
  documentUnitId,
  excerpts,
  loading,
  setFilePosition,
  triggerDeepSearch,
  notSearching,
}: ChunkPreviewColumnProps) => {
  const { t } = useTranslation();

  const sortedExcerpts = useMemo(() => {
    const uniqueExcerpts = [
      ...new Map(excerpts.map((excerpt) => [excerpt.pageNumber, excerpt])).values(),
    ];
    return uniqueExcerpts.sort((a, b) => a.pageNumber - b.pageNumber);
  }, [excerpts]);

  return (
    !notSearching && (
      <div className="flex flex-col items-stretch h-full">
        <div className="flex flex-col w-[300px] flex-shrink overflow-hidden bg-white shadow-qura rounded-md">
          <div className="border-b border-gray-200 text-sm text-center py-5 h-[100px]">
            <p>{t('searchDocumentPage.title')}</p>
            <div className="h-3" />
            {sortedExcerpts.length > 1 ? (
              <p className="text-sm text-qura-neutral-balanced">
                {t('searchDocumentPage.foundChunksCount', {
                  count: sortedExcerpts.length,
                })}
              </p>
            ) : sortedExcerpts.length === 1 ? (
              <p className="text-sm text-qura-neutral-balanced">
                {t('searchDocumentPage.foundChunksCount_one', {
                  count: sortedExcerpts.length,
                })}
              </p>
            ) : (
              <p className="text-sm text-qura-neutral-balanced">
                {t('searchDocumentPage.noChunksFound')}
              </p>
            )}
          </div>
          {triggerDeepSearch && (
            <button
              className="flex items-center text-sm mt-5 bg-qura-neutral-jet self-center py-1 shadow-sm rounded-md cursor-pointer border hover:bg-qura-neutral-balanced"
              onClick={triggerDeepSearch}>
              <Icons.NewSearch className="h-3.5 ml-2 mr-1.5 mb-0.5 text-white" />
              <p className="mr-3 text-white">{t('searchDocumentPage.deepSearch')}</p>
            </button>
          )}
          <div className="px-12 pt-6 pb-10 flex flex-col gap-8 overflow-y-auto items-stretch">
            {sortedExcerpts.map(
              (excerpt) =>
                excerpt.fileModifications.pageOverrides &&
                excerpt.fileModifications.pageOverrides.length > 0 && (
                  <ChunkPreview
                    documentId={documentId}
                    documentUnitId={documentUnitId}
                    pageNumber={excerpt.pageNumber}
                    pageModifications={excerpt.fileModifications.pageOverrides[0]}
                    offset={excerpt.offset}
                    key={excerpt.pageNumber}
                    setFilePosition={setFilePosition}
                    score={excerpt.score}
                  />
                ),
            )}
            {loading &&
              Array(MAX_CHUNKS_PER_DOCUMENT - sortedExcerpts.length)
                .fill(undefined)
                .map((_, index) => <LoadingChunk key={`loading-${index}`} />)}
          </div>
        </div>
      </div>
    )
  );
};
