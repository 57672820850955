import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  environment: window.location.href.split('://')[1].split('/')[0].split(':')[0],
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllText: false,
    }),
    posthog.sentryIntegration({
      organization: import.meta.env.VITE_SENTRY_ORG,
      projectId: import.meta.env.VITE_SENTRY_PROJECT,
      severityAllowList: ['error', 'info', 'warning'],
    }),
  ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  sendDefaultPii: true,
});
