import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { REST_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { fileModificationsSchema } from '../commonSchemas';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';
import { alertError } from '@/utils/alertError';

const excerptSchema = z
  .object({
    offset: z.number(),
    page_number: z.number(),
    file_modifications: fileModificationsSchema,
    score: z.number(),
  })
  .transform(convertSnakeToCamelCaseObject);

export type Excerpt = z.infer<typeof excerptSchema>;

const inDocumentSearchResultSchema = z
  .object({
    document_id: z.string(),
    query: z.string(),
    file_modifications: fileModificationsSchema,
    excerpts: z.array(excerptSchema),
  })
  .transform(convertSnakeToCamelCaseObject);

export type InDocumentSearchResult = z.infer<typeof inDocumentSearchResultSchema>;

async function getInDocumentSearch(
  documentId: string,
  query: string,
  documentUnitId?: string,
): Promise<InDocumentSearchResult | null> {
  if (!query || query.length === 0) return null;
  try {
    const token = await getAuthToken();
    const response = await axios.post(
      `${REST_API_URL}/search/in/${documentId}?document_unit_id=${documentUnitId}`,
      {
        query,
      },
      {
        responseType: 'json',
        ...standardAxiosOptions(token),
      },
    );
    return inDocumentSearchResultSchema.parse(response.data);
  } catch (error) {
    alertError(error, { function: 'getInDocumentSearch' }, { documentId, query, documentUnitId });
    throw error;
  }
}

export const useInDocumentSearch = (
  documentId: string,
  query: string,
  documentUnitId?: string,
  enabled?: boolean,
) => {
  return useQuery({
    queryKey: ['in_document_search', documentId, query],
    queryFn: () => getInDocumentSearch(documentId, query, documentUnitId),
    retry: 1,
    enabled,
  });
};
