import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import { useParams } from 'react-router-dom';
import { FileModifications } from '@/services/api/commonSchemas';
import { Icons } from '@/assets';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { getFile } from '@/services/api/actions/useFile';
import { alertError } from '@/utils/alertError';

type DownloadButtonProps = {
  documentId: string;
  documentUnitId?: string;
  fileModifications?: FileModifications;
  identifier: string;
};

const DownloadButton = (props: DownloadButtonProps) => {
  const { documentId, documentUnitId, fileModifications, identifier } = props;
  const { searchId = '' } = useParams();
  const posthog = usePostHog();
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState<'print' | 'download'>('download');
  const [downloading, setDownloading] = useState(false);

  const showDropdownClick = (trigger: 'print' | 'download') => {
    setShowDropdown(!showDropdown || trigger !== selectedOption);
    setSelectedOption(trigger);
  };

  const onDownloadDocumentWithHighlighting = () => {
    posthog.capture(POSTHOG_EVENT.DOWNLOAD_PDF_WITH_HIGHLIGHTING, {
      documentId,
      documentUnitId,
      identifier,
      searchId,
      fileModifications,
    });
    setShowDropdown(false);
    setDownloading(true);
    if (selectedOption === 'download') {
      downloadPDF(fileModifications);
    } else {
      printPDF(fileModifications);
    }
  };

  const onDownloadDocumentWithoutHighlighting = () => {
    posthog.capture(POSTHOG_EVENT.DOWNLOAD_PDF_WITHOUT_HIGHLIGHTING, {
      documentId,
      documentUnitId,
      identifier,
      searchId,
      fileModifications,
    });
    setShowDropdown(false);
    setDownloading(true);
    if (selectedOption === 'download') {
      downloadPDF();
    } else {
      printPDF();
    }
  };

  const onDownloadHighlightedPages = () => {
    posthog.capture(POSTHOG_EVENT.DOWNLOAD_PDF_ONLY_HIGHLIGHTS, {
      documentId,
      documentUnitId,
      identifier,
      searchId,
      fileModifications,
    });
    setShowDropdown(false);
    setDownloading(true);
    const customFileModifications: FileModifications = {
      pageSelection: [
        0,
        ...(fileModifications?.pageOverrides
          ?.filter((override) => override.highlights.length > 0)
          .map((override) => override.pageNumber) ?? []),
      ],
      pageOverrides: fileModifications?.pageOverrides ?? [],
    };

    if (selectedOption === 'download') {
      downloadPDF(customFileModifications);
    } else {
      printPDF(customFileModifications);
    }
  };

  const downloadPDF = async (fileModifications?: FileModifications) => {
    const blob = await getFile(documentId, documentUnitId ?? '', fileModifications);
    const fileUrl = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', `${identifier.replace(/[^a-zåäö0-9]+/gi, '-')}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloading(false);
  };

  const printPDF = async (fileModifications?: FileModifications) => {
    let fileUrl: string | undefined;
    try {
      const blob = await getFile(documentId, documentUnitId ?? '', fileModifications);
      fileUrl = URL.createObjectURL(blob);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = fileUrl;
      document.body.appendChild(iframe);
      iframe.onload = () => {
        iframe.contentWindow?.print();
      };
    } catch (error) {
      alertError(error, { function: 'printPDF', component: 'DownloadButton' }, { documentId });
    }
    setDownloading(false);
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowDropdown(false);
      setDownloading(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="relative">
      <div className="flex items-center h-7 rounded-md border border-qura-neutral-silver bg-qura-neutral-ghost overflow-visible">
        <button
          className="w-7 h-full text-qura-neutral-jet flex items-center justify-center border-r border-qura-neutral-silver hover:bg-white focus:outline-none transition-colors rounded-l-md"
          aria-label="Print"
          onClick={() => showDropdownClick('print')}
          disabled={downloading}>
          {downloading && selectedOption === 'print' ? (
            <div className="animate-spin rounded-full h-3 w-3 border-t-2 border-b-2 border-qura-neutral-jet"></div>
          ) : (
            <Icons.Print className="size-3.5" />
          )}
        </button>
        <button
          className="w-7 h-full text-qura-neutral-jet flex items-center justify-center hover:bg-white focus:outline-none transition-colors rounded-r-md"
          aria-label="Download"
          onClick={() => showDropdownClick('download')}
          disabled={downloading}>
          {downloading && selectedOption === 'download' ? (
            <div className="animate-spin rounded-full h-3 w-3 border-t-2 border-b-2 border-qura-neutral-jet"></div>
          ) : (
            <Icons.Download className="size-3.5" />
          )}
        </button>
      </div>
      {showDropdown && (
        <div
          ref={dropdownRef}
          className="absolute bg-white rounded-lg flex flex-col shadow-qura px-2 py-2 z-10 left-1/2 transform -translate-x-1/2 top-full mt-1 text-xs w-max">
          <button
            className="rounded-[6px] p-2 cursor-pointer hover:bg-qura-neutral-ghost text-left whitespace-nowrap"
            onClick={onDownloadDocumentWithHighlighting}
            disabled={downloading}>
            <p>
              {selectedOption === 'download'
                ? t('searchDocumentPage.downloadDocumentWithHighlights')
                : t('searchDocumentPage.printDocumentWithHighlights')}
            </p>
          </button>
          <button
            className="rounded-[6px] p-2 cursor-pointer hover:bg-qura-neutral-ghost text-left whitespace-nowrap"
            onClick={onDownloadDocumentWithoutHighlighting}
            disabled={downloading}>
            <p>
              {selectedOption === 'download'
                ? t('searchDocumentPage.downloadDocumentWithoutHighlights')
                : t('searchDocumentPage.printDocumentWithoutHighlights')}
            </p>
          </button>
          <button
            className="rounded-[6px] p-2 cursor-pointer hover:bg-qura-neutral-ghost text-left whitespace-nowrap"
            onClick={onDownloadHighlightedPages}
            disabled={downloading}>
            <p>
              {selectedOption === 'download'
                ? t('searchDocumentPage.downloadHighlightedPages')
                : t('searchDocumentPage.printHighlightedPages')}
            </p>
          </button>
        </div>
      )}
    </div>
  );
};

export default DownloadButton;
