import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadButton from './DownloadButton';
import ZoomInput from './ZoomInput';
import Tag from '../Tag/Tag';
import { TestViewerPosition } from '../TextViewer/TextViewer';
import { Icons } from '@/assets';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { DocumentProperties } from '@/services/api/actions/useDocumentProperties';
import { FileModifications } from '@/services/api/commonSchemas';

const PageInput = ({
  pageCount,
  pageScrollPosition,
  setFilePosition,
}: {
  pageCount: number;
  pageScrollPosition: number;
  setFilePosition: (position: TestViewerPosition) => void;
}) => {
  const [inputValue, setInputValue] = useState(pageScrollPosition + 1);

  useEffect(() => {
    setInputValue(pageScrollPosition + 1);
  }, [pageScrollPosition]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPage = parseInt(event.target.value, 10);
    setInputValue(newPage);
  };

  const handleInputBlur = () => {
    let newPage = inputValue - 1;
    if (isNaN(newPage) || newPage < 0) {
      newPage = 0;
    } else if (pageCount && newPage >= pageCount) {
      newPage = pageCount - 1;
    }
    setFilePosition({ pageNumber: newPage, offset: 5 });
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  return (
    <div className="flex flex-shrink-0 items-center">
      <input
        type="number"
        className="w-7 text-xs rounded text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:outline-none ring-1 ring-qura-neutral-silver hover:ring-qura-neutral-jet focus:ring-qura-neutral-balanced mr-2 "
        min={1}
        max={pageCount}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyDown={handleInputKeyDown}
        data-np-intersection-state="hidden"
        autoComplete="off"
      />
      <div className="flex items-center">
        <span className="text-xs text-qura-neutral-jet">/</span>
        <span className="text-xs text-qura-neutral-jet m-2">{pageCount}</span>
      </div>
    </div>
  );
};

type DocumentViewerTopBarProps = {
  scale: number;
  setScale: (value: number) => void;
  documentProperties?: DocumentProperties;
  documentId: string;
  documentUnitId?: string;
  fileModifications?: FileModifications;
  pageScrollPosition: number;
  setFilePosition: (position: TestViewerPosition) => void;
};

export const DocumentViewerTopBar = ({
  scale,
  setScale,
  documentProperties,
  documentId,
  documentUnitId,
  fileModifications,
  pageScrollPosition,
  setFilePosition,
}: DocumentViewerTopBarProps) => {
  const { t } = useTranslation();

  const legalId = documentProperties?.legalId ?? '-';
  const title = documentProperties?.title ?? '-';
  const sourceLink = documentProperties?.sourceLink;
  const day = documentProperties?.day;
  const month = documentProperties?.month;
  const year = documentProperties?.year;

  const publicationDate = day && month && year ? new Date(year, month, day).toDateString() : '-';

  return (
    <div className="flex flex-col border-b px-4 py-3">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center w-1/3">
          <Popover>
            <PopoverTrigger>
              <Tag className="cursor-pointer flex overflow-hidden flex-shrink md bg-qura-neutral-ghost rounded px-2 border hover:border-transparent hover:bg-qura-neutral-mist">
                <Icons.Document className="mr-2 mb-[1px] flex-shrink-0" />
                <p
                  className="font-medium overflow-hidden text-ellipsis whitespace-nowrap flex-shrink"
                  title={legalId}>
                  {legalId}
                </p>
                <Icons.Info className="ml-2.5 flex-shrink-0 w-3.5 h-3.5" />
              </Tag>
            </PopoverTrigger>
            <PopoverContent className="w-[500px]" side="top" align="start" sideOffset={30}>
              <div className="flex flex-col gap-6">
                <p className="text-m ">{title}</p>
                <div>
                  <div className="flex justify-between">
                    <p className="text-sm text-qura-neutral-jet pr-2">
                      {t('documentMetadata.legal_id')}
                    </p>
                    <p className="text-sm text-qura-neutral-balanced">{legalId}</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-sm text-qura-neutral-jet pr-2">
                      {t('documentMetadata.publication_date')}
                    </p>
                    <p className="text-sm text-qura-neutral-balanced">{publicationDate}</p>
                  </div>
                </div>
                {sourceLink && (
                  <div className="flex items-center justify-between gap-10">
                    <p className="text-sm text-qura-neutral-jet">{t('documentMetadata.source')}</p>
                    <Tag
                      onClick={() => {
                        if (sourceLink) {
                          window.open(sourceLink, '_blank');
                        }
                      }}
                      className={`${sourceLink ? 'cursor-pointer' : 'cursor-not-allowed'} flex overflow-hidden flex-shrink md bg-white rounded px-2 border`}>
                      <p className="font-medium overflow-hidden text-ellipsis whitespace-nowrap flex-shrink">
                        {sourceLink?.replace(/^https?:\/\//, '')}
                      </p>
                      {sourceLink && (
                        <Icons.ArrowTiltedUpRight className="mr-2 mb-[1px] flex-shrink-0 -rotate-90" />
                      )}
                    </Tag>
                  </div>
                )}
              </div>
            </PopoverContent>
          </Popover>
        </div>
        {documentProperties ? (
          <div className="flex items-center justify-center w-1/3">
            <PageInput
              pageCount={documentProperties.pageCount}
              pageScrollPosition={pageScrollPosition}
              setFilePosition={setFilePosition}
            />
          </div>
        ) : (
          <p className="text-sm animate-pulse w-1/3 text-center">{t('common.loading')}</p>
        )}
        <div className="flex items-center justify-end gap-4 w-1/3">
          <ZoomInput scale={scale} setScale={setScale} />
          <DownloadButton
            documentId={documentId}
            documentUnitId={documentUnitId}
            fileModifications={fileModifications}
            identifier={documentProperties?.legalId ?? documentProperties?.title ?? ''}
          />
        </div>
      </div>
      {/* <div className="overflow-hidden relative">
        <p
          className={`text-sm text-qura-neutral-jet ${isExpanded ? '' : 'truncate'} pr-6`}
          title={title}
          onClick={toggleExpand}>
          {title}
        </p>
        <button
          onClick={toggleExpand}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 text-qura-neutral-jet hover:text-qura-neutral-balanced"
          aria-label={isExpanded ? 'Collapse title' : 'Expand title'}>
          <Icons.Arrow
            className={`w-3 h-3 transition-transform ${isExpanded ? 'rotate-180' : ''}`}
          />
        </button>
      </div> */}
    </div>
  );
};
