import { usePostHog } from 'posthog-js/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoadingResultCard } from './LoadingResultCard';
import { Filtering } from '../../components/Filtering/Filtering';
import Layout from '../../components/Layout/Layout';
import { SearchResult } from '../../components/SearchResult/SearchResult';
import SearchStatus from '../../components/SearchStatus/SearchStatus';
import { createNewSearch } from '../../state/actions/createNewSearch';
import {
  loadOldSearch,
  setParams,
  setQuery,
  setSearchMode,
} from '../../state/actions/searchParams';
import { sendSearchParams } from '../../state/actions/sendSearchParams';
import { useSearchStore } from '../../state/searchStore';
import LoadingGradient from '../../components/LoadingGradient/LoadingGradient';
import { useDebounceResults } from '../../state/hooks/useDebounceResults';
import { useDataRole } from '../../state/hooks/useDataRole';
import { ResultDocument } from '@/services/api/commonSchemas';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { getOldSearch } from '@/services/firebase/functions/getOldSearch';
import { alertError } from '@/utils/alertError';
import DatabaseInfoModal from '@/features/Search/components/DatabaseInfoModal/DatabaseInfoModal';
import { Icons } from '@/assets';
import devlog from '@/utils/devlog';
import { useAvailableFilterTags } from '@/services/api/actions/useAvailableFilterTags';
import { NPSFeedback } from '@/features/Feedback/components/NPSFeedback';
import { NotFound } from '@/features/Common/Error/404';

type ResultPageStatus =
  | 'idle'
  | 'searching'
  | 'search-error'
  | 'loading-old-search'
  | 'old-search-error';

const RESULT_PAGE_SCROLL = 'resultPageScroll';
const LOADING_CARDS = 20;

export const SearchResultPage = () => {
  const { searchId = '' } = useParams();
  const { dataRole, dataRoleUrlParam } = useDataRole();
  const posthog = usePostHog();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: tagsFilter } = useAvailableFilterTags(dataRole);
  const [hasTriedToLoadOldSearch, setHasTriedToLoadOldSearch] = useState(false);
  const query = useSearchStore((state) => state.currentParams.query);
  const hasSearch = useSearchStore((state) => searchId in state.crossDocumentSearches);

  const progress = useSearchStore(
    (state) => state.crossDocumentSearches[searchId]?.results?.progress ?? null,
  );
  const { resultsToShow, resultsFetched, noticeWillBeRemoved, waitingForResults } =
    useDebounceResults(searchId);

  const [isDataBaseInfoModalOpened, setIsDataBaseInfoModalOpened] = useState(false);

  const status = useMemo<ResultPageStatus>(() => {
    if (hasSearch) {
      if (waitingForResults) {
        return 'searching';
      }
    } else {
      if (hasTriedToLoadOldSearch) {
        return 'old-search-error';
      } else {
        return 'loading-old-search';
      }
    }
    return 'idle';
  }, [hasSearch, hasTriedToLoadOldSearch, waitingForResults]);

  useEffect(() => {
    setSearchMode('crossDocumentSearch');
  }, []);

  useEffect(() => {
    const loadSearch = async () => {
      if (searchId && !hasSearch) {
        setHasTriedToLoadOldSearch(false);
        try {
          const { result, submittedParams } = await getOldSearch(searchId);
          // Update progress stages
          result.progress.stages = Object.fromEntries(
            Object.entries(result.progress.stages).map(([key, stage]) => [
              key,
              {
                ...stage,
                status: 'completed',
              },
            ]),
          );

          loadOldSearch(searchId, result, submittedParams);
          setParams(submittedParams);
        } catch (err) {
          console.error('Error fetching old search:', err);
          alertError(err, { function: 'getOldSearch' }, { searchId });
          devlog('Error loading old search:', err);
        } finally {
          setHasTriedToLoadOldSearch(true);
        }
      }
    };

    loadSearch();
  }, [searchId, hasSearch]);

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem(RESULT_PAGE_SCROLL);
    const scrollY = (() => {
      try {
        if (scrollPosition) {
          const { _searchId, _scrollY } = JSON.parse(scrollPosition);
          if (_searchId === searchId) {
            return _scrollY;
          }
          sessionStorage.removeItem(RESULT_PAGE_SCROLL);
        }
        return 0;
      } catch (error) {
        devlog('Error parsing scroll position:', error);
        sessionStorage.removeItem(RESULT_PAGE_SCROLL);
        return 0;
      }
    })();
    window.scrollTo(0, scrollY);
  }, [searchId]);

  const onSearch = (query: string) => {
    setQuery(query);
    createNewSearch()
      .then(({ searchId }) => {
        sendSearchParams(searchId, dataRole);
        navigate(`/${dataRoleUrlParam}/search/${searchId}`);
      })
      .catch((error) => {
        alertError(error, { function: 'onSearch', component: 'SearchResultPage' });
        toast.error(t('errors.searchFailed'));
      });
  };

  const onOpen = (document: ResultDocument, chunkIndex: number) => {
    if (!dataRole) return;
    const chunk = document.outputChunks[chunkIndex];
    posthog.capture(POSTHOG_EVENT.SEARCH_RESULT_OPEN, {
      chunk: true,
      relevanceScore: document.relevanceScore,
      documentId: document.documentId,
      searchId,
    });
    sessionStorage.setItem(
      RESULT_PAGE_SCROLL,
      JSON.stringify({ _searchId: searchId, _scrollY: window.scrollY }),
    );

    navigate(
      `/${dataRoleUrlParam}/document/${document.documentId}?pageNumber=${chunk.pageNumber + 1}&offset=${Math.floor(chunk.heightOffset)}?&searchId=${searchId}&loadSearch=true`,
    );
  };

  const onTryAgain = () => {
    posthog.capture(POSTHOG_EVENT.SEARCH_TRY_AGAIN, { searchId });
    onSearch(query);
  };

  const handleDataBaseInfoModalStatus = () => {
    setIsDataBaseInfoModalOpened(!isDataBaseInfoModalOpened);
  };

  return (
    <Layout
      query={query}
      onSearch={onSearch}
      contentClassName="w-[calc(100%-700px)] max-w-[850px] min-w-[75%] lg:min-w-[600px] "
      leftContent={() => {
        if (['search-error', 'old-search-error'].includes(status)) {
          return null;
        }
        return tagsFilter ? (
          <div className="flex flex-col items-stretch h-full px-2 -mx-2 -mt-1 pt-1 ">
            <Filtering searchId={searchId} tagsFilter={tagsFilter} />
            <div className="flex flex-1 max-h-10 items-center justify-center">
              <button
                onClick={handleDataBaseInfoModalStatus}
                className="flex text-xs leading-tight hover:underline self-center bg-qura-bg px-2 py-1 rounded-full">
                <Icons.Info className="text-black mr-1 mt-[1px]" />
                <p>{t('databaseInformation.databaseInformation')}</p>
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-filter items-center py-5 gap-5 bg-white shadow-qura rounded">
            {Array.from({ length: 10 }).map((_, index) => (
              <LoadingGradient className="w-[90%] h-3 opacity-40 rounded-sm" key={index} />
            ))}
          </div>
        );
      }}
      rightContent={() => (
        <>
          {!['search-error', 'old-search-error'].includes(status) && (
            <SearchStatus foundDocuments={resultsFetched.length} progress={progress} />
          )}
        </>
      )}>
      {['search-error'].includes(status) && (
        <div className="flex flex-col items-center justify-center h-screen w-screen fixed top-0 left-0 bg-qura-bg">
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center w-full max-w-3xl px-3">
            <h1 className="text-2xl font-medium text-qura-neutral-jet mb-3">
              {t('errors.unknown')}
            </h1>
            <p className="text-sm text-qura-neutral-balanced mb-6 text-center">
              {t('errors.search404Description')}
            </p>
            <button
              onClick={onTryAgain}
              className="bg-qura-neutral-jet text-white px-5 py-1.5 rounded-full text-sm hover:bg-qura-neutral-jet-medium transition-colors">
              {t('common.tryAgain')}
            </button>
          </div>
        </div>
      )}

      {['old-search-error'].includes(status) && (
        <NotFound
          title={t('errors.search404Title')}
          description={t('errors.search404Description')}
        />
      )}

      <div className="flex flex-col gap-6 pb-20">
        {['idle', 'searching'].includes(status) && resultsFetched.length > 0
          ? resultsToShow.map((result, i) => (
              <SearchResult
                resultDocument={result}
                onOpen={onOpen}
                key={result.documentId}
                willBeRemoved={
                  noticeWillBeRemoved && result.documentId !== resultsFetched[i]?.documentId
                }
              />
            ))
          : status === 'idle' && (
              <div className="flex flex-col items-center justify-center h-screen w-screen fixed top-0 left-0 bg-qura-bg">
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center w-full max-w-3xl px-3">
                  <h1 className="text-2xl font-medium text-qura-neutral-jet mb-3">
                    {t('searchResult.noSearchResults')}
                  </h1>
                  <p className="text-sm text-qura-neutral-balanced mb-2 text-center">
                    {t('searchResult.tryUpdateFilters')}
                  </p>
                  <p className="text-sm text-qura-neutral-balanced mb-6 text-center">
                    {t('errors.search404Description')}
                  </p>
                  <button
                    onClick={onTryAgain}
                    className="bg-qura-neutral-jet text-white px-5 py-1.5 rounded-full text-sm hover:bg-qura-neutral-jet-medium transition-colors">
                    {t('common.tryAgain')}
                  </button>
                </div>
              </div>
            )}

        {(['loading-old-search', 'searching'].includes(status) ||
          resultsFetched.length !== resultsToShow.length) &&
          Array(LOADING_CARDS)
            .fill(undefined)
            .map((_, index) => (
              <LoadingResultCard
                key={index}
                willBeRemoved={
                  noticeWillBeRemoved && resultsToShow.length + index < resultsFetched.length
                }
              />
            ))}
      </div>
      <NPSFeedback />
      {isDataBaseInfoModalOpened && <DatabaseInfoModal onClose={handleDataBaseInfoModalStatus} />}
    </Layout>
  );
};
